exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-blog-post-slug-tsx": () => import("./../../../src/pages/blog/{BlogPost.slug}.tsx" /* webpackChunkName: "component---src-pages-blog-blog-post-slug-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-page-slug-tsx": () => import("./../../../src/pages/{Page.slug}.tsx" /* webpackChunkName: "component---src-pages-page-slug-tsx" */),
  "component---src-pages-services-single-service-page-slug-tsx": () => import("./../../../src/pages/services/{SingleServicePage.slug}.tsx" /* webpackChunkName: "component---src-pages-services-single-service-page-slug-tsx" */),
  "component---src-pages-solutions-and-services-tsx": () => import("./../../../src/pages/solutions-and-services.tsx" /* webpackChunkName: "component---src-pages-solutions-and-services-tsx" */),
  "slice---src-components-common-scroll-to-top-index-tsx": () => import("./../../../src/components/common/scroll-to-top/index.tsx" /* webpackChunkName: "slice---src-components-common-scroll-to-top-index-tsx" */),
  "slice---src-layout-footer-index-tsx": () => import("./../../../src/layout/footer/index.tsx" /* webpackChunkName: "slice---src-layout-footer-index-tsx" */),
  "slice---src-layout-header-index-tsx": () => import("./../../../src/layout/header/index.tsx" /* webpackChunkName: "slice---src-layout-header-index-tsx" */)
}

